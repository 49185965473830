import React, { useState } from "react";

import { Cascader, Space, Tooltip } from "antd";
import type { DefaultOptionType } from "antd/es/cascader";

import { remove as _remove, escapeRegExp, isEmpty, isNumber, isString, truncate } from "lodash";
import { useCustomer, useDashboardContext } from "../hooks";
import { Sentiment } from "../indexTypes";
import {
  ConversationMetadataField,
  ConversationMetadataFieldTypeName,
  Settings,
} from "../types/dashboardTypes";
import {
  BooleanExpression,
  ComparableExpression,
  EqOp,
  MatchesOp,
  MetadataBooleanValueField,
  MetadataNumericValueField,
  MetadataStringValueField,
  ParsedExpression,
  StringExpression,
} from "../types/expressionsDslTypes";
import { getDatasetIdsFromParsedExpression } from "../utils";

/**
 * Merge filter keys that are mapped to the same display name so we can show them as a single filter
 */
const mergeMetadataFieldsWithSameDisplayName = (
  viewMetadataFields: ConversationMetadataField[]
) => {
  const uniqueDisplayNames = new Set(viewMetadataFields.map(field => field.displayName));

  const mergedOptions = Array.from(uniqueDisplayNames).map(displayName => {
    const filtered = viewMetadataFields.filter(field => field.displayName === displayName);
    const keys = filtered.map(field => field.fieldName);
    const mergedOptions = filtered
      .map(field => field.fieldType.options)
      .flat()
      .sort();

    const uniqueOptions: Set<string | number | boolean> = new Set();
    const uniqueMergedOptions = mergedOptions.filter(option => {
      if (isString(option) && !uniqueOptions.has(option.toLowerCase())) {
        uniqueOptions.add(option.toLowerCase());
        return true;
      }
      if (!isString(option) && !uniqueOptions.has(option)) {
        uniqueOptions.add(option);
        return true;
      }
      return false;
    });

    return { keys, displayName, options: uniqueMergedOptions };
  });

  return mergedOptions;
};

const buildMetadataFilterExpression = (
  opId: MatchesOp["op_id"] | EqOp<StringExpression | ComparableExpression>["op_id"],
  lhsFieldId:
    | MetadataNumericValueField["field_id"]
    | MetadataStringValueField["field_id"]
    | MetadataBooleanValueField["field_id"],
  lhsMetadataKey:
    | MetadataNumericValueField["metadata_key"]
    | MetadataStringValueField["metadata_key"]
    | MetadataBooleanValueField["metadata_key"],
  rhsValue: string | number | boolean
): BooleanExpression => {
  return {
    expr: {
      op_id: opId,
      lhs: { expr: { field_id: lhsFieldId, metadata_key: lhsMetadataKey } },
      rhs: { expr: rhsValue },
    },
  } as BooleanExpression;
};

const validateMetadataFieldValues = (
  values: (string | number)[],
  metadataFieldType: string | undefined
): (string | number | boolean)[] => {
  let isValid;
  let validValues: (string | number | boolean)[] = values;
  const error = new Error("Invalid metadata field type");
  switch (metadataFieldType) {
    case ConversationMetadataFieldTypeName.BOOLEAN:
      isValid = values.every(val => val === "true" || val === "false");
      validValues = values.map(val => val === "true");
      break;
    case ConversationMetadataFieldTypeName.CATEGORICAL_NUMERIC:
      isValid = values.every(val => isNumber(val));
      break;
    case ConversationMetadataFieldTypeName.CATEGORICAL_STRING:
      isValid = values.every(val => isString(val));
      break;
    default:
      throw error;
  }
  if (!isValid) {
    throw error;
  }
  return validValues;
};

const buildMetadataFilterExpressions = (
  keys: string[],
  values: (string | number)[],
  metadataFields: ConversationMetadataField[]
): BooleanExpression => {
  const exprAry: BooleanExpression[] = keys.map(key => {
    const metadataFieldType = metadataFields.find(field => field.fieldName === key)?.fieldType
      .metadata_field_type;
    const validValues = validateMetadataFieldValues(values, metadataFieldType);

    if (metadataFieldType === ConversationMetadataFieldTypeName.CATEGORICAL_NUMERIC) {
      const exprs = validValues.map(val =>
        buildMetadataFilterExpression("EQ", "metadata_numeric_value", key, val)
      );
      const metadataExpressions =
        exprs.length === 1 ? exprs[0] : { expr: { op_id: "ANY", expressions: exprs } };
      return metadataExpressions as BooleanExpression;
    } else if (metadataFieldType === ConversationMetadataFieldTypeName.BOOLEAN) {
      const exprs = validValues.map(val =>
        buildMetadataFilterExpression("EQ", "metadata_boolean_value", key, val)
      );
      const metadataExpressions =
        exprs.length === 1 ? exprs[0] : { expr: { op_id: "ANY", expressions: exprs } };
      return metadataExpressions as BooleanExpression;
    } else if (metadataFieldType === ConversationMetadataFieldTypeName.CATEGORICAL_STRING) {
      // Construct a string that is python-parseable regex
      let regex = values
        .map(val => {
          if (!isString(val)) {
            throw new Error("Invalid filter value");
          }
          return escapeRegExp(val);
        })
        .join("|");
      // Make the regex case-insensitive
      regex = `(?i)${regex}`;

      return buildMetadataFilterExpression("MATCHES", "metadata_value", key, regex);
    } else {
      throw new Error("Invalid metadata field type");
    }
  });

  if (exprAry.length === 0) {
    throw new Error("Invalid filter value");
  }
  const expr = exprAry.length === 1 ? exprAry[0] : { expr: { op_id: "ANY", expressions: exprAry } };
  return expr as BooleanExpression;
};

export const MultiFilter: React.FC<{
  viewFilters: Settings["filters"];
}> = ({ viewFilters }) => {
  const { dispatch, state } = useDashboardContext();
  const viewExpression = state.view?.expression;
  const { customer } = useCustomer();
  const [error, setError] = useState<string | undefined>(undefined);
  const FILTER_FIELD_TYPES = [
    ConversationMetadataFieldTypeName.CATEGORICAL_STRING,
    ConversationMetadataFieldTypeName.CATEGORICAL_NUMERIC,
    ConversationMetadataFieldTypeName.BOOLEAN,
  ];

  const viewDatasets = viewExpression ? getDatasetIdsFromParsedExpression(viewExpression) : [];
  const viewMetadataFields = customer.conversationMetadataFields.filter(
    field =>
      viewDatasets.includes(field.datasetId) &&
      Object.keys(viewFilters).includes(field.fieldName) &&
      FILTER_FIELD_TYPES.includes(field.fieldType.metadata_field_type)
  );

  interface Option {
    key: string;
    value: string | number; // Must be string or number; Cascader does not support other types
    label: string | number | React.JSX.Element;
    children?: Option[];
    parent?: string;
    parentDisplayName?: string;
  }

  type SelectedOptions = (string | number)[][];

  const SENTIMENT_KEY = "sentiment";
  const sentimentChildren = ["positive", "negative", "neutral"].map(entry => ({
    key: entry,
    value: entry,
    label: entry,
    parent: SENTIMENT_KEY,
    parentDisplayName: "Sentiment",
  }));
  const sentimentOption = {
    key: SENTIMENT_KEY,
    value: SENTIMENT_KEY,
    label: "Issue Sentiment",
    children: sentimentChildren,
  };

  // When fields point to the same display name, merge them into a single filter
  const mergedFilterSettings = mergeMetadataFieldsWithSameDisplayName(viewMetadataFields);

  const options: Option[] = mergedFilterSettings.map(
    (filterConfig: {
      keys: string[];
      displayName: string;
      options: (string | number | boolean)[];
    }) => {
      // JSONify the keys since they're arrays; cascader does not support array keys or values.
      const fieldKey = JSON.stringify(filterConfig.keys);

      return {
        key: fieldKey,
        value: fieldKey,
        label: filterConfig.displayName,
        children: filterConfig.options.map((childOption: string | number | boolean) => ({
          key: childOption.toString(),
          value:
            isString(childOption) || isNumber(childOption) ? childOption : childOption.toString(),
          label:
            isString(childOption) && childOption.length > 30 ? (
              <Tooltip title={childOption}>{truncate(childOption, { length: 30 })}</Tooltip>
            ) : (
              childOption.toString()
            ),
          parent: fieldKey,
          parentDisplayName: filterConfig.displayName,
        })),
      };
    }
  );

  options.push(sentimentOption);

  const filtersToExpression = (selectedOptions: SelectedOptions): ParsedExpression | undefined => {
    try {
      if (selectedOptions.length === 0) {
        return;
      }

      // Group the selected filters by their keys and build expressions for each group
      const uniqueJsonMetadataKeys = Array.from(
        new Set(selectedOptions.map(option => option[0] as string))
      );
      const expressions = uniqueJsonMetadataKeys.map(metadataKeysStr => {
        const values = selectedOptions
          .filter(option => option[0] === metadataKeysStr)
          .map(option => option[1]);
        const keys = JSON.parse(metadataKeysStr) as string[];
        const metadata_fields = viewMetadataFields.filter(field => keys.includes(field.fieldName));
        return buildMetadataFilterExpressions(keys, values, metadata_fields);
      });

      // Wrap the expressions in an ALL operator if there are multiple
      const topLevelExpression: BooleanExpression =
        expressions.length === 1 ? expressions[0] : { expr: { op_id: "ALL", expressions } };

      const filterExpression: ParsedExpression = {
        version: 1,
        parsed: topLevelExpression,
      };
      return filterExpression;
    } catch (e) {
      console.error(e);
      setError("Filters could not be applied");
    }
  };

  const onChange = (selectedOptions: SelectedOptions) => {
    setError(undefined);
    // A quirk of Cascader is that when all child options are selected, only the parent value
    // is provided in the SelectedOption. We could ignore these if we knew that no values were
    // null, but we can't guarantee that. Add each child value back to the selected options so
    // that the filter can be applied as expected.
    let validSelectedOptions: (string | number)[][] = [];
    selectedOptions.forEach(opt => {
      if (opt.length == 1) {
        const allChildOpts = options
          .find(item => item.key === opt[0])
          ?.children?.map(child => child.value);
        if (allChildOpts && !isEmpty(allChildOpts)) {
          validSelectedOptions = validSelectedOptions.concat(
            allChildOpts.map(child => [opt[0], child])
          );
        }
      } else {
        validSelectedOptions.push(opt);
      }
    });

    // Remove the sentiment filters from the selected options so we can handle it separately
    const selectedSentiment = _remove(
      validSelectedOptions,
      value => value[0] === SENTIMENT_KEY
    ).map(value => value[1]);

    const MAX_FILTERS = 10;
    if (validSelectedOptions.length > MAX_FILTERS) {
      setError(`Please select ${MAX_FILTERS} or fewer filters`);
      return;
    }
    const formattedFilters = filtersToExpression(validSelectedOptions);
    dispatch({ type: "SET_FILTERS", payload: formattedFilters });
    dispatch({
      type: "SET_TAXONOMY_NODE_FILTERS",
      payload: { sentiment: selectedSentiment as Sentiment[] },
    });
  };

  const renderSelectedFilters = (
    labels: string[],
    selectedOptions: DefaultOptionType[] | undefined
  ) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      return;
    }

    const option = selectedOptions[selectedOptions.length - 1];
    const label = labels[labels.length - 1];
    if (selectedOptions.length > 1 && option.parent) {
      return (
        <span key={option.value}>
          {option.parentDisplayName}: {label}
        </span>
      );
    } else {
      return <span key={option.value}>{option.label}: All (not null)</span>;
    }
  };

  return (
    <Space>
      <Cascader
        dropdownClassName="dashboard-multi-filter-cascader"
        style={{ minWidth: 300, width: "100%" }}
        placeholder="Apply Filter(s)"
        options={options}
        onChange={onChange}
        displayRender={renderSelectedFilters}
        multiple
        status={error ? "error" : undefined}
        maxTagCount={7} // Selected options are truncated in the UI after maxTagCount items
      />
      {error && <span style={{ color: "red" }}>{error}</span>}
    </Space>
  );
};
