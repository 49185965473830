import { Sentiment, TimeseriesMetadata } from "../indexTypes";
import { ParsedExpression } from "./expressionsDslTypes";

export type CustomerId = string;
export type TaxonomyId = string;

export interface View {
  id: string;
  name: string;
  description?: string;
  taxonomyId: TaxonomyId;
  customerId: CustomerId;
  settings: Settings;
  expression: ParsedExpression;
}

export interface Settings {
  defaultGranularity: string;
  defaultPeriodLength?: number;
  defaultStartDate?: string;
  defaultSelectedMetadata?: string[];
  crmUrlTemplate?: string;

  filters: { [key: string]: { display_name: string } };
  metadataAnalysisOptions: MetadataAnalysisOptions;

  legendText?: string;
}

export interface MetadataAnalysisOptions {
  [key: string]: { display_name: string; is_default?: boolean };
}

export interface FilterOptions {
  [key: string]: (string | number)[];
}

export type TaxonomyNodeId = string;

export interface TaxonomyNodeItem {
  id: TaxonomyNodeId;
  resolvedName: string;
  children: TaxonomyNodeItem[];
}

export interface Taxonomy {
  id: string;
  name?: string;
  description?: string;
  rootNodeIds: string[];
  nodes: TaxonomyNodeItem[];
}

export interface TaxonomyTimeseriesMetadata {
  id: string;
  name: string;
  mean: number;
  total: number;
  rawCounts: TimeseriesMetadata;
  percentCounts: TimeseriesMetadata;
}

export interface TaxonomyNodeData {
  id: TaxonomyNodeId;
  name: string;
  childIds: string[];
  grandchildCount: number;
  categoryId: number;
  mean: number;
  total: number;
  percent: number;
  rawCounts: TimeseriesMetadata;
  percentCounts: TimeseriesMetadata;
  latestPeriodTrend: number;
}

export interface OrderedTaxonomyLeafNodeData {
  taxonomyNodeId: TaxonomyNodeId;
  conversationCount: number;
  sentiment: Sentiment;
  data?: TaxonomyNodeData;
}

export type MetadataCounts = {
  metadataFieldName: string;
  metadataValueCounts: { [metadataValueName: string]: number };
};

export type MetadataCountsChartDatum = {
  key: string;
  value: number;
  count: number;
  name: string;
};

export enum ConversationMetadataFieldTypeName {
  CATEGORICAL_STRING = "categorical_string",
  CATEGORICAL_NUMERIC = "categorical_numeric",
  BOOLEAN = "boolean",
}

export type BooleanMetadataFieldType = {
  metadata_field_type: ConversationMetadataFieldTypeName.BOOLEAN;
  options: boolean[];
};

export type CategoricalStringMetadataFieldType = {
  metadata_field_type: ConversationMetadataFieldTypeName.CATEGORICAL_STRING;
  options: string[];
};

export type CategoricalNumericMetadataFieldType = {
  metadata_field_type: ConversationMetadataFieldTypeName.CATEGORICAL_NUMERIC;
  options: number[];
};

export type ConversationMetadataFieldTypes =
  | CategoricalStringMetadataFieldType
  | CategoricalNumericMetadataFieldType
  | BooleanMetadataFieldType;

export type ConversationMetadataField = {
  id: string;
  customerId: CustomerId;
  datasetId: number;
  fieldName: string;
  displayName: string;
  fieldType: ConversationMetadataFieldTypes;
};

export type SearchNodesResponse = {
  matchingTaxonomyNodeIds: Set<TaxonomyNodeId>;
};
