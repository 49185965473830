/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { UserOutlined } from "@ant-design/icons";
import { Button, Col, Menu, Row, Select, Space, Spin } from "antd";
import { Auth } from "aws-amplify";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import "../App.less";
import { BaseHeader } from "../components/Header";
import { CustomerUIModel } from "../hooks";

export const ChooseCustomer = ({
  currentUser,
  customers,
  setCustomer,
  isLoading,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentUser: any;
  customers: CustomerUIModel[];
  setCustomer: Dispatch<SetStateAction<CustomerUIModel | undefined>>;
  isLoading: boolean;
}) => {
  // Make sure that a selected customer dashboard sticks through refresh
  useEffect(() => {
    const customerId = localStorage.getItem("chosenCustomerId");
    if (customerId && customers.length) {
      const cachedCustomer = customers.find(c => {
        return c.id === customerId;
      });
      setCustomer(cachedCustomer);
    }
  }, [customers]);

  const handleChange = (id: string) => {
    const chosenCustomer = customers.filter(customer => customer.id === id)[0];
    setCustomer(chosenCustomer);
    localStorage.setItem("chosenCustomerId", chosenCustomer.id);
  };

  return (
    <div>
      <BaseHeader>
        <Menu
          theme="dark"
          mode="horizontal"
          style={{
            lineHeight: "64px",
            float: "right",
          }}
        >
          <Menu.SubMenu
            key="choose-customer-submenu"
            title={
              <span>
                <UserOutlined />
                {currentUser?.attributes.email ?? "Spiral User"}
              </span>
            }
          >
            <Menu.ItemGroup title="Account:">
              <Menu.Item key="signout">
                <Button onClick={() => Auth.signOut()} danger>
                  Sign Out
                </Button>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu.SubMenu>
        </Menu>
      </BaseHeader>

      {!isLoading && customers.length <= 0 ? (
        "You are not authorized to view customer data."
      ) : (
        <Row align="middle" justify="center" style={{ minHeight: "50vh" }}>
          <Col span={8}>
            <div className="customer-selector">
              <Space direction="vertical" size="large">
                {customers.length > 1 && (
                  <div>
                    <h3>You have access to multiple dashboards</h3>
                    <h2>Select which dashboard to load:</h2>
                  </div>
                )}
                <div className="customer-buttons">
                  <Spin spinning={isLoading}>
                    <Space direction="vertical" size="large">
                      <Select
                        autoFocus
                        style={{ width: 200 }}
                        placeholder="Select a dashboard..."
                        onChange={handleChange}
                        filterOption={(input, option) =>
                          (option!.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        showSearch
                      >
                        {customers
                          .sort((a, b) =>
                            a.index.displayName
                              .toLowerCase()
                              .localeCompare(b.index.displayName.toLowerCase())
                          )
                          .map(customer => (
                            <Select.Option key={customer.id} value={customer.id}>
                              {customer.index.displayName}
                            </Select.Option>
                          ))}
                      </Select>
                    </Space>
                  </Spin>
                </div>
              </Space>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
