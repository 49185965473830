import {
  Affix,
  Alert,
  Tooltip as AntTooltip,
  Card,
  Col,
  DatePicker,
  Empty,
  Input,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
  Switch,
  Typography,
} from "antd";
import moment, { Moment } from "moment";
import React, { useEffect } from "react";
import { useParams } from "react-router";

import { forEach } from "lodash";
import { MetadataAnalysisWidget } from "../components/MetadataAnalysisWidget";
import { MultiFilter } from "../components/MultiFilter";
import { TaxonomyNode } from "../components/TaxonomyNode";
import { TimeseriesChart } from "../components/TimeseriesChart";
import { DashboardContext, useDashboardContext, useDashboardReducer } from "../hooks";
import { isAPIError, TaxonomyNodeFilters } from "../indexTypes";
import {
  getOrderedTaxonomyNodes,
  getSearchSemanticMatchedTaxonomyNodesResults,
  getTaxonomy,
  getTaxonomyNode,
  getTaxonomyOverviewMetadata,
  getView
} from "../reportApi";
import {
  CustomerId,
  MetadataAnalysisOptions,
  TaxonomyId,
  TaxonomyNodeData,
  TaxonomyNodeId,
  TaxonomyNodeItem,
  TaxonomyTimeseriesMetadata
} from "../types/dashboardTypes";
import { ParsedExpression } from "../types/expressionsDslTypes";

import "../components/ReportsViewer.less";
import "./Dashboard.less";

const { Title } = Typography;

const GranularitySelector: React.FC<{
  granularity: string;
  onGranularityChange: (value: string) => void;
}> = ({ granularity, onGranularityChange }) => (
  <Space>
    <label htmlFor="granularity">Show by:</label>
    <Select id="granularity" defaultValue={granularity} onChange={onGranularityChange}>
      <Select.Option value="hour">Hour</Select.Option>
      <Select.Option value="day">Day</Select.Option>
      <Select.Option value="week">Week</Select.Option>
      <Select.Option value="month">Month</Select.Option>
    </Select>
  </Space>
);

const OverviewCard: React.FC<{
  taxonomyLoading: boolean;
  taxonomyTimeseriesMetadata?: TaxonomyTimeseriesMetadata;
  chartTitle: string;
  viewId: string;
  startDate: string;
  endDate: string;
  metadataAnalysisChoice: string;
  filters?: ParsedExpression;
  legendText: string;
}> = ({
  taxonomyLoading: loading,
  taxonomyTimeseriesMetadata,
  chartTitle,
  viewId,
  startDate,
  endDate,
  metadataAnalysisChoice,
  filters,
  legendText,
}) => (
  <Row key="reportOverviewCard">
    <Col span={24}>
      <Card className="reportOverviewCard rounded" style={{ margin: "0 24px 24px" }}>
        <Row>
          <Col span={16} style={{ textAlign: "center" }}>
            <Spin spinning={loading || !taxonomyTimeseriesMetadata?.id}>
              <AntTooltip title={() => "Total Contacts in this Report"}>
                <Statistic value={taxonomyTimeseriesMetadata?.total} />
              </AntTooltip>
              <TimeseriesChart
                key={taxonomyTimeseriesMetadata?.id}
                metadata={taxonomyTimeseriesMetadata?.rawCounts}
                secondaryMetadata={taxonomyTimeseriesMetadata?.percentCounts}
                graphheight={300}
                xaxis
                yaxis
                grid
                animationDuration={150}
                preserve={"preserveEnd"}
                percent={false}
                xTickFormat={"MMM D"}
                legendText={legendText}
                showTooltip
              />
            </Spin>
          </Col>
          <Col span={8}>
            <MetadataAnalysisWidget
              chartTitle={chartTitle}
              type="bar"
              viewId={viewId}
              startDate={startDate}
              endDate={endDate}
              metadataAnalysisChoice={metadataAnalysisChoice}
              filters={filters}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  </Row>
);

const OverviewCardContainer = () => {
  const { state } = useDashboardContext();
  if (
    state.view &&
    !state.taxonomyLoading &&
    !state.taxonomyNodeListLoading &&
    (!state.orderedTaxonomyLeafNodes?.length || !state.taxonomyNodeData?.length)
  ) {
    return (
      <Empty
        style={{ padding: "30px 0" }}
        description="No data available with current filters applied!"
      />
    );
  }

  return (
    <OverviewCard
      taxonomyLoading={state.taxonomyLoading}
      taxonomyTimeseriesMetadata={state.taxonomyTimeseriesMetadata}
      chartTitle={
        state.metadataAnalysisChoice &&
        state.view?.settings.metadataAnalysisOptions[state.metadataAnalysisChoice]
          ? state.view.settings.metadataAnalysisOptions[state.metadataAnalysisChoice].display_name
          : ""
      }
      viewId={state.view?.id || ""}
      startDate={state.startDate.format("YYYY-MM-DD")}
      endDate={state.endDate.format("YYYY-MM-DD")}
      metadataAnalysisChoice={state.metadataAnalysisChoice || ""}
      filters={state.filters}
      legendText={state.view?.settings.legendText || "Contacts"}
    />
  );
};

const TaxonomyNodeList: React.FC<{
  taxonomyNodeData: TaxonomyNodeData[];
  granularity: string;
  startDate: string;
  endDate: string;
  taxonomyId: string;
  viewId: string;
  metadataAnalysisOptions: MetadataAnalysisOptions;
  metadataAnalysisChoice: string;
  filters?: ParsedExpression;
  taxonomyNodeFilters?: TaxonomyNodeFilters;
}> = ({
  taxonomyNodeData,
  granularity,
  startDate,
  endDate,
  taxonomyId,
  viewId,
  metadataAnalysisOptions,
  metadataAnalysisChoice,
  filters,
  taxonomyNodeFilters,
}) => {
  const sortedNodeData = [...taxonomyNodeData]
    .filter(e => e.total !== 0)
    .sort((a, b) => b.total - a.total);
  return (
    <Row style={{ margin: "0 24px 24px" }}>
      {sortedNodeData.map((nodeData: TaxonomyNodeData) => (
        <Col span={24} key={nodeData.id}>
          <TaxonomyNode
            taxonomyNodeData={nodeData}
            granularity={granularity}
            startDate={startDate}
            endDate={endDate}
            taxonomyId={taxonomyId}
            viewId={viewId}
            metadataAnalysisOptions={metadataAnalysisOptions}
            metadataAnalysisChoice={metadataAnalysisChoice}
            filters={filters}
            taxonomyNodeFilters={taxonomyNodeFilters}
          />
        </Col>
      ))}
    </Row>
  );
};

const DateRangeSelector: React.FC<{
  startDate: Moment;
  endDate: Moment;
  onDateChange: (dates: [Moment, Moment]) => void;
}> = ({ startDate, endDate, onDateChange }) => (
  <Space>
    <label htmlFor="datePicker">Report Range:</label>
    <DatePicker.RangePicker
      id="datePicker"
      onChange={dates => {
        if (dates && dates[0] && dates[1]) {
          onDateChange([dates[0], dates[1]]);
        }
      }}
      defaultValue={[startDate, endDate]}
      disabledDate={current => current > moment()}
      className="rounded"
    />
  </Space>
);

const MetadataAnalysisSelector: React.FC<{
  metadataAnalysisChoice: string;
  metadataAnalysisOptions: MetadataAnalysisOptions;
  onMetadataAnalysisChange: (value: string) => void;
}> = ({ metadataAnalysisChoice, metadataAnalysisOptions, onMetadataAnalysisChange }) => {
  return (
    <Space>
      <label htmlFor="metadataAnalysisSelector">Analyze by:</label>
      <Select
        value={metadataAnalysisChoice}
        defaultValue={metadataAnalysisChoice}
        onChange={onMetadataAnalysisChange}
        style={{ width: "150px" }}
      >
        {Object.entries(metadataAnalysisOptions).map(([fieldName, analysisConfigs]) => (
          <Select.Option key={fieldName} value={fieldName}>
            {analysisConfigs.display_name}
          </Select.Option>
        ))}
      </Select>
    </Space>
  );
};

const MetadataAnalysisSelectorContainer = () => {
  const { state, dispatch } = useDashboardContext();

  const handleMetadataAnalysisChange = (value: string) => {
    dispatch({ type: "SET_METADATA_ANALYSIS_CHOICE", payload: value });
  };

  return (
    <MetadataAnalysisSelector
      metadataAnalysisChoice={state.metadataAnalysisChoice ?? ""}
      metadataAnalysisOptions={state.view?.settings.metadataAnalysisOptions || {}}
      onMetadataAnalysisChange={handleMetadataAnalysisChange}
    />
  );
};

const IssueGroupToggle: React.FC = () => {
  const { state, dispatch } = useDashboardContext();
  return (
    <Switch
      checkedChildren="Grouped"
      unCheckedChildren="Ungrouped"
      onChange={(checked: boolean) => {
        dispatch({ type: "SET_ISSUES_GROUPED", payload: checked });
      }}
      defaultChecked
      disabled={state.taxonomyNodeListLoading}
      checked={state.issuesGrouped}
    />
  );
};

const getLeafNodeIds = (nodes: TaxonomyNodeItem[]) => {
  const leafNodeIds: TaxonomyNodeId[] = [];
  nodes.forEach(node => {
    if (node.children.length) {
      leafNodeIds.push(...getLeafNodeIds(node.children));
    } else {
      leafNodeIds.push(node.id);
    }
  });
  return leafNodeIds;
};

export const Dashboard: React.FC = () => {
  const { viewId } = useParams<{ viewId: string }>();
  const [state, dispatch] = useDashboardReducer();
  const ISSUES_PER_PAGE = 20;

  useEffect(() => {
    const controller = new AbortController();
    const fetchViewData = async () => {
      dispatch({ type: "FETCH_DATA" });
      if (!viewId) {
        dispatch({ type: "SET_ERROR", payload: "No view ID provided" });
        return;
      }
      try {
        const viewResp = await getView(viewId, controller.signal);
        if (isAPIError(viewResp)) {
          dispatch({ type: "SET_ERROR", payload: "Error fetching view" });
          return;
        }

        dispatch({ type: "SET_VIEW", payload: viewResp });
      } catch (error) {
        dispatch({ type: "SET_ERROR", payload: "Error fetching taxonomy" });
      } finally {
        dispatch({ type: "SET_VIEW_LOADING", payload: false });
      }
    };

    fetchViewData();
    return () => controller.abort();
  }, [viewId, dispatch]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      dispatch({ type: "SET_TAXONOMY_LOADING", payload: true });
      dispatch({ type: "SET_TAXONOMY_NODE_LIST_LOADING", payload: true });
      try {
        if (state.view?.id && state.view.taxonomyId) {
          const taxonomyId = state.view.taxonomyId;
          const startDate = state.startDate.format("YYYY-MM-DD");
          const endDate = state.endDate.format("YYYY-MM-DD");

          const taxonomyPromise = getTaxonomy(taxonomyId, controller.signal);
          const taxonomyTimeseriesPromise = getTaxonomyOverviewMetadata(
            taxonomyId,
            state.view.id,
            startDate,
            endDate,
            state.granularity,
            state.filters,
            state.taxonomyNodeFilters,
            controller.signal
          );
          const promises = [taxonomyPromise, taxonomyTimeseriesPromise];
          const [taxonomyResp, taxonomyTimeseriesResp] = await Promise.all(promises);

          if (isAPIError(taxonomyResp) || isAPIError(taxonomyTimeseriesResp)) {
            dispatch({ type: "SET_ERROR", payload: "Error fetching taxonomy" });
            return;
          }
          dispatch({ type: "SET_TAXONOMY", payload: taxonomyResp });
          dispatch({ type: "SET_TAXONOMY_TIMESERIES", payload: taxonomyTimeseriesResp });

          const leafNodeIds = getLeafNodeIds(taxonomyResp.nodes);
          const context = {
            granularity: state.granularity,
            startDate: state.startDate.format("YYYY-MM-DD"),
            endDate: state.endDate.format("YYYY-MM-DD"),
            filters: state.filters,
            taxonomyNodeFilters: state.taxonomyNodeFilters,
          };
          const orderedLeafNodes = await getOrderedTaxonomyNodes(
            taxonomyId,
            state.view.id,
            leafNodeIds,
            context,
            controller.signal
          );
          dispatch({ type: "SET_ORDERED_TAXONOMY_LEAF_NODES", payload: orderedLeafNodes });
        }
      } catch (error) {
        if (!controller.signal.aborted) {
          dispatch({ type: "SET_ERROR", payload: "Error fetching taxonomy" });
        }
      } finally {
        if (!controller.signal.aborted) {
          dispatch({ type: "SET_TAXONOMY_LOADING", payload: false });
        }
      }
    };

    fetchData();
    return () => controller.abort();
  }, [
    dispatch,
    state.view?.id,
    state.view?.taxonomyId,
    state.startDate,
    state.endDate,
    state.granularity,
    state.filters,
    state.taxonomyNodeFilters,
  ]);

  useEffect(() => {
    const controller = new AbortController();
    if (
      state.view &&
      state.taxonomy &&
      !state.taxonomyLoading &&
      !state.orderedTaxonomyLeafNodes?.length
    ) {
      dispatch({ type: "SET_TAXONOMY_NODE_DATA", payload: [] });
      dispatch({ type: "SET_TAXONOMY_NODE_LIST_LOADING", payload: false });
      return;
    }

    dispatch({ type: "SET_TAXONOMY_NODE_LIST_LOADING", payload: true });

    if (
      !state.view ||
      !state.taxonomy ||
      !state.orderedTaxonomyLeafNodes?.length ||
      state.taxonomyLoading
    ) {
      return;
    }

    const fetchNodeData = async (nodeIdList: string[]) => {
      const nodeDataPromises = nodeIdList.map(
        nodeId =>
          state.taxonomy?.id &&
          state.view?.id &&
          getTaxonomyNode(
            state.taxonomy.id,
            nodeId,
            state.view.id,
            {
              granularity: state.granularity,
              startDate: state.startDate.format("YYYY-MM-DD"),
              endDate: state.endDate.format("YYYY-MM-DD"),
              filters: state.filters,
              taxonomyNodeFilters: state.taxonomyNodeFilters,
            },
            controller.signal
          )
      );
      return await Promise.all(nodeDataPromises);
    };

    const getNodeData = async () => {
      try {
        if (!state.issuesGrouped) {
          const page = state.taxonomyLeafNodePage;
          const endIndex = page * ISSUES_PER_PAGE;
          const startIndex = endIndex - ISSUES_PER_PAGE;
          const nodes =
            (state.visibleTaxonomyNodeIds
              ? state.orderedTaxonomyLeafNodes?.filter(node =>
                  state.visibleTaxonomyNodeIds?.has(node.taxonomyNodeId)
                )
              : state.orderedTaxonomyLeafNodes) || [];

          const dataToShow = nodes.slice(startIndex, endIndex);
          const dataToFetch = dataToShow
            .filter(orderedNodeItem => !orderedNodeItem.data)
            .map(n => n.taxonomyNodeId);

          const nodeDataResults = await fetchNodeData(dataToFetch);
          forEach(nodeDataResults, (nodeData: TaxonomyNodeData) => {
            const orderedNodeItem = state.orderedTaxonomyLeafNodes?.find(
              node => node.taxonomyNodeId === nodeData.id
            );
            if (orderedNodeItem) {
              orderedNodeItem.data = nodeData;
            }
          });

          const leafNodeData = dataToShow
            .filter(n => n.data)
            .map(n => n.data) as TaxonomyNodeData[];

          dispatch({ type: "SET_TAXONOMY_NODE_DATA", payload: leafNodeData });
        } else if (state.taxonomyRootNodeData.length) {
          dispatch({ type: "SET_TAXONOMY_NODE_DATA", payload: state.taxonomyRootNodeData });
        } else {
          const nodeDataResults = await fetchNodeData(state.taxonomy?.rootNodeIds || []);
          dispatch({ type: "SET_TAXONOMY_ROOT_NODE_DATA", payload: nodeDataResults });
          dispatch({ type: "SET_TAXONOMY_NODE_DATA", payload: nodeDataResults });
        }
      } catch (error) {
        if (!controller.signal.aborted) {
          dispatch({ type: "SET_TAXONOMY_NODE_DATA", payload: [] });
          dispatch({ type: "SET_ERROR", payload: "Error fetching issues" });
        }
      } finally {
        if (!controller.signal.aborted && !state.taxonomyLoading) {
          dispatch({ type: "SET_TAXONOMY_NODE_LIST_LOADING", payload: false });
        }
      }
    };

    getNodeData();
    return () => controller.abort();
  }, [
    dispatch,
    state.view,
    state.taxonomy,
    state.issuesGrouped,
    state.orderedTaxonomyLeafNodes,
    state.taxonomyLeafNodePage,
    state.startDate,
    state.endDate,
    state.filters,
    state.granularity,
    state.taxonomyRootNodeData,
    state.visibleTaxonomyNodeIds,
    state.taxonomyLoading,
    state.taxonomyNodeFilters,
  ]);

  useEffect(() => {
    const controller = new AbortController();
    const filterMatchingNodes = async (
      customerId: CustomerId,
      taxonomyId: TaxonomyId,
      nodes: TaxonomyNodeItem[],
      searchValue: string | undefined
    ): Promise<void> => {
      try {
        let semanticMatchedTaxonomyNodeIds: Set<TaxonomyNodeId> | undefined = undefined;
        if (searchValue) {
          const searchRes = 
            await getSearchSemanticMatchedTaxonomyNodesResults(customerId, taxonomyId, searchValue, controller.signal);
          semanticMatchedTaxonomyNodeIds = searchRes.matchingTaxonomyNodeIds;
        }

        const matchSentimentPred = (node: TaxonomyNodeItem) => {
          if (node.children.length || !state.taxonomyNodeFilters?.sentiment.length) {
            return true;
          }
          const nodeSentiment = state.orderedTaxonomyLeafNodes?.find(
            n => n.taxonomyNodeId === node.id
          )?.sentiment;
          return !!nodeSentiment && state.taxonomyNodeFilters.sentiment.includes(nodeSentiment);
        };

        const matchNodePred = (node: TaxonomyNodeItem) => {
          if (!state.issuesGrouped && node.children.length > 0) {
            return false; // If the issues aren't grouped, we're only matching L3 nodes.
          }

          if (!matchSentimentPred(node)) {
            return false; // If the sentiment doesn't match, we're definitely not showing it.
          }

          return semanticMatchedTaxonomyNodeIds?.has(node.id)
            || !searchValue
            || node.resolvedName.toLowerCase().includes(searchValue.trim().toLowerCase());
        };

        // Internal implementation so that we can mutate the res in place w/o making caller care about that.
        const _collectMatchingNodeIds = (
          nodes: TaxonomyNodeItem[],
          pred: (node: TaxonomyNodeItem) => boolean,
          res: Set<TaxonomyNodeId>
        ): boolean => {
          let foundMatch = false;
          for (const curr of nodes) {
            if (pred(curr)) {
              foundMatch = true;
              res.add(curr.id);
              if (state.issuesGrouped) {
                // If issues are grouped then we need to mark all children of the matching node visible
                // unless they're excluded by the sentiment filter.
                _collectMatchingNodeIds(curr.children, matchSentimentPred, res);
              }
            } else {
              const childrenMatched = _collectMatchingNodeIds(curr.children, pred, res);
              if (childrenMatched) {
                foundMatch = true;
                if (state.issuesGrouped) {
                  // If the current node didn't match, but issues are grouped and some of the curr node's children
                  // matched then we need the parent to be visible.
                  res.add(curr.id);
                }
              }
            }
          }
          return foundMatch;
        };

        const visibleNodes = new Set<TaxonomyNodeId>();
        _collectMatchingNodeIds(nodes, matchNodePred, visibleNodes);

        dispatch({ type: "SET_VISIBLE_TAXONOMY_NODES", payload: visibleNodes });
      } catch (error) {
        if (!controller.signal.aborted) {
          dispatch({ type: "SET_ERROR", payload: "Error searching taxonomy" });
        }
      } finally {
        if (!controller.signal.aborted) {
          dispatch({ type: "SET_TAXONOMY_NODE_LIST_LOADING", payload: false });
        }
      }
    };

    if (!state.issuesGrouped) {
      dispatch({ type: "SET_TAXONOMY_LEAF_NODE_PAGINATION", payload: 1 });
    }
    if (state.taxonomy?.nodes && state.view) {
      dispatch({ type: "SET_TAXONOMY_NODE_LIST_LOADING", payload: true });
      filterMatchingNodes(state.view.customerId, state.taxonomy.id, state.taxonomy.nodes, state.submittedIssueSearchInput);
      return () => controller.abort();
    }
  }, [
    dispatch,
    state.submittedIssueSearchInput,
    state.taxonomyNodeFilters,
    state.view,
    state.issuesGrouped,
    state.orderedTaxonomyLeafNodes,
    state.taxonomy,
  ]);

  const DateRangeSelectorContainer: React.FC = () => {
    const handleDateChange = (dates: [Moment, Moment]) => {
      dispatch({
        type: "SET_DATES",
        payload: { startDate: dates[0], endDate: dates[1] },
      });
    };

    return (
      <DateRangeSelector
        startDate={state.startDate}
        endDate={state.endDate}
        onDateChange={handleDateChange}
      />
    );
  };

  const searchBar = () => {
    const handleSearchInputChange = (value?: string) => {
      dispatch({ type: "SET_ISSUE_SEARCH_INPUT", payload: value });
    };

    return (
      <span style={{ paddingLeft: "10px", width: "50%" }}>
        <Input.Search
          allowClear
          className="rounded search-bar"
          placeholder="Search Issues"
          onSearch={
            // Since search involves network requests, search when the user manually triggers a search rather than 
            // `onChange`which would cause a network req for each character change.
            val => handleSearchInputChange(val)
          }
          onChange={val => {
            // We'll make sure that when the user manually deletes their search, or clicks the clear input button, the
            // page automatically reverts to showing all nodes.
            if (val.target.value.length === 0) {
              handleSearchInputChange(undefined);
            }
          }}
          enterButton="Search"
          loading={
            // Only show spinning search button if there's a non-empty input and we're still waiting on the results.
            state.submittedIssueSearchInput !== undefined && state.submittedIssueSearchInput.length > 0 && state.taxonomyNodeListLoading
          }
        />
      </span>
    );
  };

  return (
    <DashboardContext.Provider value={{ state, dispatch }}>
      <div id="reportsviewer">
        <Affix className="topcontrols">
          <div>
            {state.error && (
              <Alert
                type="error"
                message={state.error}
                className="rounded"
                showIcon
                closable
                onClose={() => dispatch({ type: "SET_ERROR", payload: undefined })}
              />
            )}
            <Row align="middle" style={{ margin: "0 24px 0px", padding: "12px 0 0 0" }}>
              <Col span={24} style={{ display: "inline-flex", alignItems: "center" }}>
                <Title level={3} style={{ display: "inline", margin: "0" }}>
                  {state.view?.name || "Support Conversation Analysis"}
                </Title>
                {state.taxonomy && searchBar()}
              </Col>
            </Row>
            <Row style={{ margin: "0 24px 0px", padding: "12px 0 0 0" }}>
              <Col span={20}>
                <Space size="small">
                  <DateRangeSelectorContainer />
                  <GranularitySelector
                    granularity={state.granularity}
                    onGranularityChange={value =>
                      dispatch({ type: "SET_GRANULARITY", payload: value })
                    }
                  />
                  <MetadataAnalysisSelectorContainer />
                  <IssueGroupToggle />
                </Space>
              </Col>
            </Row>
            <Row style={{ margin: "0 24px 0px", padding: "12px 0 12px 0" }}>
              <Col span={24}>
                {state.view && <MultiFilter viewFilters={state.view.settings.filters} />}
              </Col>
            </Row>
          </div>
        </Affix>
        <OverviewCardContainer />
        <Spin spinning={state.taxonomyNodeListLoading} tip="Loading Issues...">
          {state.view &&
            state.taxonomy &&
            state.metadataAnalysisChoice &&
            state.taxonomyNodeData && (
              <TaxonomyNodeList
                taxonomyNodeData={state.taxonomyNodeData}
                granularity={state.granularity}
                startDate={state.startDate.format("YYYY-MM-DD")}
                endDate={state.endDate.format("YYYY-MM-DD")}
                taxonomyId={state.taxonomy.id}
                viewId={state.view.id}
                metadataAnalysisOptions={state.view.settings.metadataAnalysisOptions}
                metadataAnalysisChoice={state.metadataAnalysisChoice}
                filters={state.filters}
                taxonomyNodeFilters={state.taxonomyNodeFilters}
              />
            )}
          {!state.issuesGrouped &&
            !state.taxonomyNodeListLoading &&
            state.orderedTaxonomyLeafNodes && (
              <Pagination
                current={state.taxonomyLeafNodePage}
                onChange={page =>
                  dispatch({ type: "SET_TAXONOMY_LEAF_NODE_PAGINATION", payload: page })
                }
                total={
                  state.visibleTaxonomyNodeIds
                    ? state.visibleTaxonomyNodeIds.size
                    : state.orderedTaxonomyLeafNodes.length
                }
                pageSize={ISSUES_PER_PAGE}
                showSizeChanger={false}
                size="small"
                style={{ textAlign: "center" }}
              />
            )}
        </Spin>
      </div>
    </DashboardContext.Provider>
  );
};
