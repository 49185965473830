import { Modal, Select, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { isAPIError } from "../indexTypes";
import { APIParams, getConversationMetadataFieldsV1, getEvidenceCsv } from "../reportApi";
import { SortableClusterSummary } from "../reports";

export const EvidenceExportModal = ({
  cluster,
  apiParams,
  reportUrlHash,
  isModalOpen,
  setIsModalOpen,
  downloadIsLoading,
  setDownloadIsLoading,
  setDisableCollapse,
  setDisableExport,
}: {
  cluster: SortableClusterSummary;
  apiParams: APIParams;
  reportUrlHash: string;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  downloadIsLoading: boolean;
  setDownloadIsLoading: (isLoading: boolean) => void;
  setDisableCollapse: (disableCollapse: boolean) => void;
  setDisableExport: (disableExport: boolean) => void;
}) => {
  const [metadataOptions, setMetadataOptions] = useState<{ label: string; value: string }[]>([]);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [optionsLoading, setOptionsLoading] = useState<boolean>(false);

  const clusterId = cluster.cluster;
  const isSuperCluster = !!cluster.supercluster;
  const clusterIds =
    isSuperCluster && cluster.children ? cluster.children?.map(c => c.cluster) : [clusterId];

  useEffect(() => {
    const getMetadataOptions = async () => {
      setOptionsLoading(true);
      try {
        const response = await getConversationMetadataFieldsV1(clusterIds, apiParams.customer);
        const respJson = await response.json();
        const metadataFields = respJson.metadata_fields;
        const options = metadataFields.map((field: string) => ({ label: field, value: field }));
        setMetadataOptions(options);
      } finally {
        setOptionsLoading(false);
      }
    };
    getMetadataOptions();
  }, []);

  const handleCancel = () => {
    setIsModalOpen(false);
    setDisableCollapse(false);
    setDisableExport(false);
  };

  const handleChange = (item: string[]) => {
    setSelectedFields(item);
  };

  const downloadEvidenceCSV = async () => {
    setDownloadIsLoading(true);
    setDisableExport(true);
    setIsModalOpen(false);
    setDisableCollapse(false);

    try {
      const response = await getEvidenceCsv(
        clusterId,
        clusterIds,
        apiParams,
        reportUrlHash,
        selectedFields
      );
      if (isAPIError(response)) {
        throw new Error(`Error downloading evidence: ${response.description}`);
      }

      if (response.body) {
        const dateFormat = "YYYY-MM-DD";
        const startDate = moment(apiParams.window.start).format(dateFormat);
        const endDate = apiParams.window.end;
        const outputFilename = `${apiParams.customer}_spiral_evidence_${startDate}_to_${endDate}.csv`;
        // I know this looks gross, but apparently this is the typical way to
        // download a file returned from an API call
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } finally {
      setDownloadIsLoading(false);
      setDisableExport(false);
    }
  };

  return (
    <Modal
      title="Export Evidence"
      open={isModalOpen}
      onOk={downloadEvidenceCSV}
      onCancel={handleCancel}
      confirmLoading={downloadIsLoading}
      mask={false}
    >
      <Spin spinning={optionsLoading}>
        <p>Select metadata fields to include in your export:</p>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="None"
          onChange={handleChange}
          options={metadataOptions}
        />
      </Spin>
    </Modal>
  );
};
